import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/phorkit/phorkit/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PageTitle } from 'docs/helpers/PageTitle';
import { StateWrapper } from 'docs/helpers/StateWrapper';
import { ThemeWrapper } from 'docs/helpers/ThemeWrapper';
import { Pagination } from '../index';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageTitle src="compositions/Pagination" title="Pagination" mdxType="PageTitle" />
    <p>{`The pagination is a wrapper for the `}<a parentName="p" {...{
        "href": "../components/ButtonGroup"
      }}>{`ButtonGroup`}</a>{`. It can use either `}<inlineCode parentName="p">{`ButtonGroup`}</inlineCode>{` styles or custom styles on top of an unstyled `}<inlineCode parentName="p">{`ButtonGroup`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "pagination"
    }}>{`Pagination`}</h2>
    <h3 {...{
      "id": "pagination-as-buttons"
    }}>{`Pagination as buttons`}</h3>
    <Playground __position={1} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPageLinks withPreviousAndNext color="primary" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "pagination-as-links"
    }}>{`Pagination as links`}</h3>
    <p><em parentName="p">{`Note that the links will cause the page to reload so the state will appear unchanged.`}</em></p>
    <Playground __position={2} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage }) => (\n      <Pagination\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        getHref={page => `#page${page}`}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage
          }) => <Pagination withIcons withPageLinks withPreviousAndNext color="primary" getHref={page => `#page${page}`} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "pagination-as-links-and-onclick"
    }}>{`Pagination as links and onClick`}</h3>
    <Playground __position={3} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        allowRightClickLinks\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        getHref={page => `#page${page}`}\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination allowRightClickLinks withIcons withPageLinks withPreviousAndNext color="primary" getHref={page => `#page${page}`} onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "pagination-with-custom-styles"
    }}>{`Pagination with custom styles`}</h2>
    <h3 {...{
      "id": "pill-shaped-pagination"
    }}>{`Pill shaped pagination`}</h3>
    <Playground __position={4} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        shape=\"pill\"\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPageLinks withPreviousAndNext color="primary" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} shape="pill" totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "pill-shaped-pagination-with-spacing"
    }}>{`Pill shaped pagination with spacing`}</h3>
    <Playground __position={5} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        shape=\"pill\"\n        spacing=\"cozy\"\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPageLinks withPreviousAndNext color="primary" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} shape="pill" spacing="cozy" totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "brick-shaped-pagination"
    }}>{`Brick shaped pagination`}</h3>
    <Playground __position={6} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPageLinks withPreviousAndNext color="primary" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "brick-shaped-pagination-with-spacing"
    }}>{`Brick shaped pagination with spacing`}</h3>
    <Playground __position={7} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        spacing=\"cozy\"\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPageLinks withPreviousAndNext color="primary" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} spacing="cozy" totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "pagination-with-text-links"
    }}>{`Pagination with text links`}</h3>
    <Playground __position={8} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withPageLinks withPreviousAndNext color="primary" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "everything-with-icons"
    }}>{`Everything with icons`}</h3>
    <Playground __position={9} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withEllipsis\n        withFirstAndLast\n        withIcons\n        withPageLabel\n        withPageLinks\n        withPreviousAndNext\n        activePageProps={{\n          weight: \'shaded\',\n          color: \'primary\',\n          style: { marginLeft: 4, marginRight: 4 },\n        }}\n        color=\"primary\"\n        ellipsisProps={{ color: \'neutral\' }}\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLabelProps={{\n          size: \'medium\',\n          style: { marginRight: 30 },\n          variants: [\'no-wrap\'],\n        }}\n        pageLinks={6}\n        pageSize={10}\n        spacing={null}\n        totalItems={300}\n        weight=\"ghost\"\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withEllipsis withFirstAndLast withIcons withPageLabel withPageLinks withPreviousAndNext activePageProps={{
            weight: 'shaded',
            color: 'primary',
            style: {
              marginLeft: 4,
              marginRight: 4
            }
          }} color="primary" ellipsisProps={{
            color: 'neutral'
          }} onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLabelProps={{
            size: 'medium',
            style: {
              marginRight: 30
            },
            variants: ['no-wrap']
          }} pageLinks={6} pageSize={10} spacing={null} totalItems={300} weight="ghost" mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "everything-with-text"
    }}>{`Everything with text`}</h3>
    <Playground __position={10} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withEllipsis\n        withFirstAndLast\n        withPageLabel\n        withPageLinks\n        withPreviousAndNext\n        color=\"primary\"\n        ellipsisProps={{\n          weight: \'ghost\',\n          color: \'neutral\',\n          style: { marginLeft: 8, marginRight: 8 },\n        }}\n        jumpProps={{ weight: \'shaded\' }}\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLabelProps={{\n          size: \'medium\',\n          style: { marginRight: 30 },\n          variants: [\'no-wrap\'],\n        }}\n        pageLinks={6}\n        pageProps={{ weight: \'shaded\' }}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withEllipsis withFirstAndLast withPageLabel withPageLinks withPreviousAndNext color="primary" ellipsisProps={{
            weight: 'ghost',
            color: 'neutral',
            style: {
              marginLeft: 8,
              marginRight: 8
            }
          }} jumpProps={{
            weight: 'shaded'
          }} onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLabelProps={{
            size: 'medium',
            style: {
              marginRight: 30
            },
            variants: ['no-wrap']
          }} pageLinks={6} pageProps={{
            weight: 'shaded'
          }} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "minimal-with-icons"
    }}>{`Minimal with icons`}</h3>
    <Playground __position={11} __code={'<ThemeWrapper>\n  <StateWrapper initialState={1}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPreviousAndNext\n        color=\"primary\"\n        justify=\"end\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageSize={10}\n        totalItems={128}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={1} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPreviousAndNext color="primary" justify="end" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageSize={10} totalItems={128} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "minimal-with-text"
    }}>{`Minimal with text`}</h3>
    <Playground __position={12} __code={'<ThemeWrapper>\n  <StateWrapper initialState={1}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withPreviousAndNext\n        color=\"primary\"\n        jumpProps={{\n          style: {\n            paddingLeft: 8,\n            paddingRight: 8,\n            marginLeft: 8,\n            marginRight: 8,\n          },\n        }}\n        justify=\"end\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageSize={10}\n        totalItems={128}\n        weight=\"ghost\"\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={1} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withPreviousAndNext color="primary" jumpProps={{
            style: {
              paddingLeft: 8,
              paddingRight: 8,
              marginLeft: 8,
              marginRight: 8
            }
          }} justify="end" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageSize={10} totalItems={128} weight="ghost" mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "minimal-with-custom-text"
    }}>{`Minimal with custom text`}</h3>
    <Playground __position={13} __code={'<ThemeWrapper>\n  <StateWrapper initialState={1}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withPreviousAndNext\n        color=\"primary\"\n        justify=\"center\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageSize={10}\n        totalItems={300}\n        translations={{\n          previousPageLabel: \'Newer\',\n          nextPageLabel: \'Older\',\n        }}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={1} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withPreviousAndNext color="primary" justify="center" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageSize={10} totalItems={300} translations={{
            previousPageLabel: 'Newer',
            nextPageLabel: 'Older'
          }} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "unstyled-pagination"
    }}>{`Unstyled pagination`}</h2>
    <h3 {...{
      "id": "page-links-with-icons"
    }}>{`Page links with icons`}</h3>
    <Playground __position={14} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPageLinks\n        withPreviousAndNext\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPageLinks withPreviousAndNext onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "page-links-with-text"
    }}>{`Page links with text`}</h3>
    <Playground __position={15} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withPageLinks\n        withPreviousAndNext\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withPageLinks withPreviousAndNext onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "everything-with-icons-1"
    }}>{`Everything with icons`}</h3>
    <Playground __position={16} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withEllipsis\n        withFirstAndLast\n        withIcons\n        withPageLabel\n        withPageLinks\n        withPreviousAndNext\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        spacing={null}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withEllipsis withFirstAndLast withIcons withPageLabel withPageLinks withPreviousAndNext onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} spacing={null} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "everything-with-text-1"
    }}>{`Everything with text`}</h3>
    <Playground __position={17} __code={'<ThemeWrapper>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withEllipsis\n        withFirstAndLast\n        withPageLabel\n        withPageLinks\n        withPreviousAndNext\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withEllipsis withFirstAndLast withPageLabel withPageLinks withPreviousAndNext onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLinks={6} pageSize={10} totalItems={300} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "minimal-with-icons-1"
    }}>{`Minimal with icons`}</h3>
    <Playground __position={18} __code={'<ThemeWrapper>\n  <StateWrapper initialState={1}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withIcons\n        withPreviousAndNext\n        justify=\"end\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageSize={10}\n        totalItems={128}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={1} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withIcons withPreviousAndNext justify="end" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageSize={10} totalItems={128} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "minimal-with-text-1"
    }}>{`Minimal with text`}</h3>
    <Playground __position={19} __code={'<ThemeWrapper>\n  <StateWrapper initialState={1}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        withPreviousAndNext\n        justify=\"end\"\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageSize={10}\n        totalItems={128}\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <StateWrapper initialState={1} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination withPreviousAndNext justify="end" onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageSize={10} totalItems={128} mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "current-page-only"
    }}>{`Current page only`}</h3>
    <Playground __position={20} __code={'<ThemeWrapper>\n  <Pagination\n    withPageLabel\n    justify=\"center\"\n    page={8}\n    pageSize={10}\n    totalItems={83}\n  />\n  <br />\n  <Pagination\n    withPageAndTotalLabel\n    justify=\"center\"\n    page={8}\n    pageSize={10}\n    totalItems={83}\n  />\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper mdxType="ThemeWrapper">
    <Pagination withPageLabel justify="center" page={8} pageSize={10} totalItems={83} mdxType="Pagination" />
    <br />
    <Pagination withPageAndTotalLabel justify="center" page={8} pageSize={10} totalItems={83} mdxType="Pagination" />
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "contrast-pagination"
    }}>{`Contrast pagination`}</h2>
    <h3 {...{
      "id": "everything-with-icons-2"
    }}>{`Everything with icons`}</h3>
    <Playground __position={21} __code={'<ThemeWrapper contrast>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        contrast\n        withEllipsis\n        withFirstAndLast\n        withIcons\n        withPageLabel\n        withPageLinks\n        withPreviousAndNext\n        activePageProps={{\n          weight: \'shaded\',\n          color: \'primary\',\n          style: { marginLeft: 4, marginRight: 4 },\n        }}\n        ellipsisProps={{ color: \'neutral\' }}\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLabelProps={{\n          size: \'medium\',\n          style: { marginRight: 30 },\n          variants: [\'no-wrap\'],\n        }}\n        pageLinks={6}\n        pageSize={10}\n        spacing={null}\n        totalItems={300}\n        weight=\"ghost\"\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination contrast withEllipsis withFirstAndLast withIcons withPageLabel withPageLinks withPreviousAndNext activePageProps={{
            weight: 'shaded',
            color: 'primary',
            style: {
              marginLeft: 4,
              marginRight: 4
            }
          }} ellipsisProps={{
            color: 'neutral'
          }} onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLabelProps={{
            size: 'medium',
            style: {
              marginRight: 30
            },
            variants: ['no-wrap']
          }} pageLinks={6} pageSize={10} spacing={null} totalItems={300} weight="ghost" mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h3 {...{
      "id": "everything-with-text-2"
    }}>{`Everything with text`}</h3>
    <Playground __position={22} __code={'<ThemeWrapper contrast>\n  <StateWrapper initialState={8}>\n    {({ state: currentPage, setState: setCurrentPage }) => (\n      <Pagination\n        contrast\n        withEllipsis\n        withFirstAndLast\n        withPageLabel\n        withPageLinks\n        withPreviousAndNext\n        ellipsisProps={{\n          weight: \'ghost\',\n          color: \'neutral\',\n          style: { marginLeft: 8, marginRight: 8 },\n        }}\n        onChangePage={(event, pageTo) => setCurrentPage(pageTo)}\n        page={currentPage}\n        pageLabelProps={{\n          size: \'medium\',\n          style: { marginRight: 30 },\n          variants: [\'no-wrap\'],\n        }}\n        pageLinks={6}\n        pageSize={10}\n        totalItems={300}\n        weight=\"shaded\"\n      />\n    )}\n  </StateWrapper>\n</ThemeWrapper>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PageTitle,
      StateWrapper,
      ThemeWrapper,
      Pagination,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ThemeWrapper contrast mdxType="ThemeWrapper">
    <StateWrapper initialState={8} mdxType="StateWrapper">
      {({
            state: currentPage,
            setState: setCurrentPage
          }) => <Pagination contrast withEllipsis withFirstAndLast withPageLabel withPageLinks withPreviousAndNext ellipsisProps={{
            weight: 'ghost',
            color: 'neutral',
            style: {
              marginLeft: 8,
              marginRight: 8
            }
          }} onChangePage={(event, pageTo) => setCurrentPage(pageTo)} page={currentPage} pageLabelProps={{
            size: 'medium',
            style: {
              marginRight: 30
            },
            variants: ['no-wrap']
          }} pageLinks={6} pageSize={10} totalItems={300} weight="shaded" mdxType="Pagination" />}
    </StateWrapper>
  </ThemeWrapper>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`[`}{`props`}{`]`}</h2>
    <Props of={Pagination} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      